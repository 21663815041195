import React from "react";
import { Navbar, Hero, About, Timeline, Contact, Skills,Footer, WhyMe, AboutText } from '../components'


const AboutPage = () => {
    

    return (
        <>
        <AboutText />
        <Contact />
        </>
    );
};

export default AboutPage;